<template>
  <v-layout align-center>
    <div class="text-body-2 slider-label">{{ label }}</div>
    <v-layout align-center>
      <slot name="min-icon"> </slot>
      <v-slider
        class="my-0 mx-2 slider-style"
        v-model="internalValue"
        :min="min"
        :max="max"
        track-color="primary"
        thumb-color="black"
        thumb-size="24"
        color="primary"
        hide-details
      ></v-slider>
      <slot name="max-icon"> </slot>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    label: {
      type: String,
    },
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>

<style lang="scss">
.slider-style .v-slider__thumb {
  height: 24px !important;
  width: 8px !important;
  left: -4px !important;
  border-radius: 5px !important;
  background-color: black !important;
}
.slider-style .v-slider__track {
  left: 0px !important;
}
.slider-style {
  min-width: 150px !important;
  max-width: 200px !important;
}
.slider-label {
  min-width: 100px;
}
.break {
  height: 1px !important;
  min-width: 200px !important;
}
</style>